import {configureStore} from "@reduxjs/toolkit";
import ModalSlice from "./slices/modalSlice";
import SessionSlice, { validateSession } from "./slices/sessionSlice";
import NavigationSlice from "./slices/navigationSlice";
import FavoritesSlice, { getStoredFavorites } from "./slices/favoritesSlice";
import UiSlice from "./slices/uiSlice";
import ProductSlice from "./slices/productsSlice";
import CmsSlice from "./slices/cmsSlice";

const store = configureStore({
    reducer: {
        session: SessionSlice,
        modal: ModalSlice,
        products: ProductSlice,
        navigation: NavigationSlice,
        favorites: FavoritesSlice,
        ui: UiSlice,
        cms: CmsSlice
    }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

store.dispatch(validateSession()).then(() => store.dispatch(getStoredFavorites()));

export default store;