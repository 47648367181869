import { ApiResponse } from "../api/api";
import { ModalType } from "../config/ModalTypes";
import useModal from "./useModal";

export default function useApi() {

    const showModal = useModal();

    return async<T = any> (apiCall: Promise<ApiResponse<T>>, showMessageOnSuccess: boolean = false, message: string = "Success!"): Promise<ApiResponse<T> | null> => {
        const result = await apiCall;

        if (result && result.canceled) return null;

        const genericError = "An error occured.";

        if (!result) {
            showModal({
                text: genericError,
                type: ModalType.Error 
            });

            return null;
        }

        if (!result.success) {
            showModal({
                text: result.message || message || genericError,
                type: ModalType.Error
            });

            return null;
        }
        
        if (showMessageOnSuccess) showModal({
            text: message,
            type: ModalType.Success
        });

        return result;
    }
}