import { ApiResponse } from "../api/api";
import { ModalType } from "../config/ModalTypes";
import { Host, getExcelVersion, isExcelVersionSupported } from "../util/versioning";
import useApi from "./useApi";
import useModal from "./useModal";
import { Buffer } from "buffer";

export default function useDownloads() {
    const callApi = useApi();
    const showModal = useModal();

    const downloadFile = async (filename: string, requiresArrayFormulas: boolean, downloadRequest: Promise<ApiResponse<string>>, extension: string = ""): Promise<void> => {
        try {
            const version = getExcelVersion();

            if (version.host === Host.Browser) {
                let message = "Please open the addin inside of Microsoft Excel to access your downloads directly. Your download will still be processed.";
                if (requiresArrayFormulas) message += "Note that this file requires Array Formulas to work, which might not be supported in the version of Microsoft Excel that you use.";

                showModal({
                    type: ModalType.Information,
                    text: message
                });
            }
            else if (requiresArrayFormulas && !isExcelVersionSupported(version)) {
                showModal({
                    text: `Please note that this file requires Array Formulas to work, which are introduced in version ${version.host === Host.Mac ? "'16.65'" : "'16.0.14326.20454'"} of Microsoft Excel. Your current version of Microsoft Excel does not support them.`,
                    type: ModalType.Information
                })
            }
            else {
                showModal({
                    text: "After downloading, make sure to save the file. If you get a Macro warning, please enable Macros via the File tab.",
                    type: ModalType.Information
                })
            }
    
            const file = await callApi(downloadRequest);
            
            if (!file || !file.success) return;
            
            const fileContents = file.data.split(";base64,");
    
            if (!fileContents || !fileContents.length || fileContents.length < 2) {
    
                showModal({
                    type: ModalType.Error,
                    text: "The downloaded file is faulty. Please try again"
                });
    
                return;
            }
    
            if (version.isDesktop) {
                await Excel.run(async (context) => {
                    await Excel.createWorkbook(fileContents[1]);
                    return context.sync();
                }).catch(err => showModal({type: ModalType.Error, text: err.message}));
    
                return;
            }
    
            const blob = new Blob([Buffer.from(fileContents[1], 'base64')], {type: fileContents[0]});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');

            link.href = url;
            
            const realExtension = extension || ".xlsm";

            link.setAttribute('download', `${filename}.${realExtension}`);
            link.setAttribute('target', '_blank');
    
            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
        }
        catch (err) {
            console.log(err);

            showModal({
                type: ModalType.Error,
                text: "Something went wrong. Please try again."
            }) 
        }
    }

    return downloadFile;
}