import axios, { AxiosHeaders, CanceledError } from "axios";
import { StatusCodes } from "http-status-codes";
import { IFavoritesSetAllRequest, IProductsDownloadSingleRequests, IProductsGetForCategoryRequest, ISecuredRequest, ISessionValidateRequest, ITutorialsGetAllRequest, IUpdateCmsLinksRequest, IUsefulLinksGetAllRequest, IUserLogInRequest, IUserLogOutRequest } from "../types/ApiRequests";
import { Cookies, getCookie } from "../util/cookies";
import { ISessionResponse } from "../types/ApiResponses";
import { IDownloadableProduct, ICmsLinkEntry, ICmsLinkCategory } from "../types/WooTypes";

const getMfaAddinRoute = (endpoint: string): string => `/mfa-addin/v1/${endpoint}`;

const ApiRoutes = {
    UsersLogIn: getMfaAddinRoute("login"),
    UsersLogOut: getMfaAddinRoute("logout"),
    SessionValidate: getMfaAddinRoute("session/validate"),
    ProductsGetForCategory: getMfaAddinRoute("products"),
    ProductsDownloadSingle: getMfaAddinRoute("products/download"),
    UsefulLinksGetAll: getMfaAddinRoute("links"),
    UsefulLinksUpdate: getMfaAddinRoute("links/update"),
    TutorialsGetAll: getMfaAddinRoute("tutorials"),
    TutorialsUpdate: getMfaAddinRoute("tutorials/update"),
    FavoritesGetAll: getMfaAddinRoute("favorites"),
    FavoritesSetAll: getMfaAddinRoute("favorites/update")
}

export enum Method {
    GET = "GET",
    POST = "POST",
    DELETE = "DELETE"
}

interface IDispatchParams<T = Object> {
    body?: T,
    method?: Method,
    showMessage?: boolean,
    showMessageOnSuccess?: boolean,
    abortController?: AbortController | null,
    abortSignal?: AbortSignal | null
}

export interface ApiResponse<T = any> {
    success: boolean,
    canceled: boolean,
    message: string,
    data: T
}


const createApiError = (showMessage: boolean, message: string = "An error occured"): ApiResponse => {
    return {
        success: false,
        canceled: false,
        message: message,
        data: null
    };
}
    
const createCanceledResponse = (): ApiResponse => ({
    canceled: true,
    success: false,
    message: "Cancelled.",
    data: null
});

const createApiSuccessPayload = <T>(payload: T): ApiResponse<T> => { 
    return ({
        success: true,
        canceled: false,
        message: "Ok.",
        data: payload
    });
}

export const getApiRequestHeader = () => {
    const headers = {};
    const sessionToken = getCookie(Cookies.Session);
    if (sessionToken) headers['Authorization'] = `Bearer ${sessionToken}`;
    return headers;
}


const apiRequest = async<RequestT = any, ResponseT = any> (endpoint = "/", {method = Method.POST, abortController, abortSignal, body, showMessage = true, showMessageOnSuccess = false}: IDispatchParams<RequestT> = {}): Promise<ApiResponse<ResponseT>>=> {
    try {
        const res = await axios({
            baseURL: "https://mfaunderwriting.com/wp-json",
            method: method,
            url: endpoint,
            headers: getApiRequestHeader(),
            signal: abortController?.signal,
            data: body
        })

        if (!res) return createApiError(showMessage);
        if (res.status >= StatusCodes.OK && res.status < StatusCodes.MULTIPLE_CHOICES) return createApiSuccessPayload<ResponseT>(res.data);

        return createApiError(showMessage, "Something went wrong.");
    }
    catch (err: any) {
        if (err instanceof CanceledError) return createCanceledResponse();
        if (err?.response?.data) return createApiError(showMessage, err.response.data);
    }

    return createApiError(showMessage);
}

export const productsGetAllForCategory = async (signal: AbortSignal, body: IProductsGetForCategoryRequest) => await apiRequest<IProductsGetForCategoryRequest, Array<IDownloadableProduct>>(ApiRoutes.ProductsGetForCategory, {body: body, abortSignal: signal});
export const productsDownloadSingle = async (body: IProductsDownloadSingleRequests) => await apiRequest<IProductsDownloadSingleRequests, string>(ApiRoutes.ProductsDownloadSingle, {body: body});

export const usersLogIn = async (body: IUserLogInRequest) => await apiRequest<IUserLogInRequest, ISessionResponse>(ApiRoutes.UsersLogIn, {body: body});
export const usersLogOut = async (body: IUserLogOutRequest) => await apiRequest<IUserLogOutRequest, string>(ApiRoutes.UsersLogOut, {body: body});

export const sessionValidate = async (signal: AbortSignal, body: ISessionValidateRequest) => await apiRequest<ISessionValidateRequest, ISessionResponse>(ApiRoutes.SessionValidate, {body: body, abortSignal: signal});

export const usefulLinksGetAll = async (signal: AbortSignal, body: IUsefulLinksGetAllRequest) => await apiRequest<IUsefulLinksGetAllRequest, Array<ICmsLinkCategory>>(ApiRoutes.UsefulLinksGetAll, {body: body, abortSignal: signal});
export const usefulLinksUpdate = async (body: IUpdateCmsLinksRequest) => await apiRequest<IUpdateCmsLinksRequest>(ApiRoutes.UsefulLinksUpdate, {body: body});

export const tutorialsGetAll = async (signal: AbortSignal, body: ITutorialsGetAllRequest) => await apiRequest<ITutorialsGetAllRequest, Array<ICmsLinkCategory>>(ApiRoutes.TutorialsGetAll, {body: body, abortSignal: signal});
export const tutorialsUpdate = async (body: IUpdateCmsLinksRequest) => await apiRequest<IUpdateCmsLinksRequest>(ApiRoutes.TutorialsUpdate, {body: body});

export const favoritesGetAll = async (signal: AbortSignal, body: ISecuredRequest) => await apiRequest(ApiRoutes.FavoritesGetAll, {body: body, abortSignal: signal});
export const favoritesSetAll = async (body: IFavoritesSetAllRequest) => await apiRequest(ApiRoutes.FavoritesSetAll, {body: body});