import { Colors } from "./AppStyles";

export enum ModalType {
    Error,
    Information,
    Success
}

export const getIconFromType = (type: ModalType) => {
    switch (type) {
        case ModalType.Error: return "x-octagon-fill";
        case ModalType.Success: return "check-circle-fill";
        default: return "info-square-fill";
    }
}

export const getColorFromType = (type: ModalType): Colors => {
    switch (type) {
        case ModalType.Error: return Colors.Error;
        case ModalType.Success: return Colors.Success;
        default: return Colors.Information;
    }
}