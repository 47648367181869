import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalType } from "../../config/ModalTypes";
import { AppRoute } from "../../routes/AppRouter";

interface INavigationSlice {
    currentRoute: AppRoute
}

export const NavigationSlice = createSlice({
    name: "navigation",
    initialState: {
        currentRoute: AppRoute.Downloads
    } as INavigationSlice,
    reducers: {
        setRoute: (state, action: PayloadAction<AppRoute>) => {
            state.currentRoute = action.payload;
        }
    }
});

export const { setRoute } = NavigationSlice.actions;

export default NavigationSlice.reducer;