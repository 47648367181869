import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Cookies, deleteCookie, getCookie, setCookie } from "../../util/cookies";
import { IStatefulSlice, Status } from "../../types/ReduxTypes";
import { sessionValidate } from "../../api/api";
import { ISessionResponse } from "../../types/ApiResponses";
import { IWooUser } from "../../types/WooTypes";

export const validateSession = createAsyncThunk<ISessionResponse, undefined, {rejectValue: any}>("session/validateSession", async (arg, {signal, rejectWithValue}) => {
    try {

        const token = getCookie(Cookies.Session);
        const uid = getCookie(Cookies.UniqueId);

        if (!token || !uid) return rejectWithValue(null);

        const validSessionResponse = await sessionValidate(signal, {token: token, uid: uid});

        if (!validSessionResponse || !validSessionResponse.success) return rejectWithValue(null);

        return validSessionResponse.data;
    }
    catch (err: any) {
        return rejectWithValue(err.response.data);
    }
});

export interface ISessionState extends IStatefulSlice {
    sessionToken: string | null,
    user: IWooUser | null,
    uniqueIdentifier: string | null
}

export const SessionSlice = createSlice({
    name: "session",
    initialState: {
        sessionToken: null,
        uniqueIdentifier: null,
        status: Status.Loading
    } as ISessionState,
    reducers: {
        setSession: (state, action: PayloadAction<ISessionResponse>) => {

            if (!action || !action.payload) {
                state.sessionToken = null;
                state.uniqueIdentifier = null;
                state.user = null;

                deleteCookie(Cookies.Session);
                deleteCookie(Cookies.UniqueId);

                return;
            }
            
            const {
                token,
                user,
                uid
            } = action.payload;

            if (!token || !user || !uid) {
                state.sessionToken = null;
                state.uniqueIdentifier = null;
                state.user = null;

                deleteCookie(Cookies.Session);
                deleteCookie(Cookies.UniqueId);

                return;
            }

            setCookie(Cookies.Session, action.payload.token, 90);
            setCookie(Cookies.UniqueId, action.payload.uid, 90);

            const sessionCookie = getCookie(Cookies.Session);

            if (!sessionCookie) {
                state.sessionToken = null;
                state.uniqueIdentifier = null;
                state.user = null;

                deleteCookie(Cookies.Session);
                deleteCookie(Cookies.UniqueId);

                return;
            }

            state.sessionToken = action.payload.token;
            state.user = action.payload.user;
            state.uniqueIdentifier = action.payload.uid;

            
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(validateSession.pending, (state, action) => {
            state.status = Status.Loading;
        })
        .addCase(validateSession.fulfilled, (state, action: PayloadAction<ISessionResponse | null>) => {
            state.status = Status.Success;
            
            const payload = action.payload;

            if (!payload) {
                state.sessionToken = null;
                state.uniqueIdentifier = null;
                state.user = null;
                return;
            }

            state.user = payload.user;
            state.sessionToken = payload.token;
            state.uniqueIdentifier = payload.uid;
        })
        .addCase(validateSession.rejected, (state, action) => {
            state.sessionToken = null;
            state.uniqueIdentifier = null;
            state.user = null;
            state.status = Status.Error;
            state.error = action.payload || action.error;
        });
    }
});

export const { setSession } = SessionSlice.actions;

export default SessionSlice.reducer;