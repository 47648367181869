import React from "react";

export interface IWindowSize {
    width: number,
    height: number
}

export default function useWindowSize() {
    const [size, setSize] = React.useState<IWindowSize>({height: 0, width: 0});

    React.useLayoutEffect(() => {
      const updateSize = () => setSize({width: window.innerWidth, height: window.innerHeight});
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
  }
  