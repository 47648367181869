import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStatefulSlice, Status } from "../../types/ReduxTypes";
import { RootState } from "../store";
import { IDownloadableProduct, WooProductCategory } from "../../types/WooTypes";
import { productsGetAllForCategory } from "../../api/api";

export const getProductsForCategory = createAsyncThunk<Array<IDownloadableProduct>, {category: WooProductCategory }, {rejectValue: any}>("products/getProductsForCategory", async (arg, {signal, getState, rejectWithValue}) => {
    try {
        if (!arg) return rejectWithValue(null);

        const {
            category
        } = arg;

        if (!category) return rejectWithValue(null);

        const {
            session
        } = getState() as RootState;

        const productsForCategory = await productsGetAllForCategory(signal, {category: category, uid: session.uniqueIdentifier, token: session.sessionToken});

        if (!productsForCategory || !productsForCategory.success) return rejectWithValue(null);

        return productsForCategory.data;
    }
    catch (err: any) {
        return rejectWithValue(err.response.data);
    }
});

export interface IProductSliceItem extends IStatefulSlice {
    products: Array<IDownloadableProduct>
}

export interface IProductSlice{
    [category: string]: IProductSliceItem
}

export const ProductSlice = createSlice({
    name: "products",
    initialState: { } as IProductSlice,
    reducers: {
        clearProducts: (state, action) => {
            for (const [key, value] of Object.entries(state)) {
                state[key] = undefined;
            }
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getProductsForCategory.pending, (state, action) => {
            if (!action.meta.arg) return;

            state[action.meta.arg.category] = {
                products: [],
                status: Status.Loading
            };
        })
        .addCase(getProductsForCategory.fulfilled, (state, action) => {
            if (!action.meta.arg) return;

            state[action.meta.arg.category].status = Status.Success;
            
            const payload = action.payload;

            if (!payload || !payload.length) {
                state[action.meta.arg.category].status = Status.Error;
                return;
            }

            state[action.meta.arg.category].products = payload;
        })
        .addCase(getProductsForCategory.rejected, (state, action) => {
            if (!action.meta.arg) return;

            state[action.meta.arg.category].status = Status.Error;
            state[action.meta.arg.category].error = action.payload || action.error;
        });
    }
});

export const { clearProducts } = ProductSlice.actions;

export default ProductSlice.reducer;