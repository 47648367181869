import { AppRoute } from "../routes/AppRouter";
import { setRoute } from "../state/slices/navigationSlice";
import { setLogoVisible } from "../state/slices/uiSlice";
import { useAppDispatch, useAppSelector } from "./reduxHooks";

export default function useRoutes() {
    const {
        currentRoute
    } = useAppSelector(state => state.navigation);

    const dispatch = useAppDispatch();

    const navigate = async (route: AppRoute) => {
        dispatch(setLogoVisible(true));
        dispatch(setRoute(route));
    }

    const isActiveRoute = (route: AppRoute) => currentRoute === route;
    const openInBrowser = (url: string) => window.open(url, "_blank");

    return {
        navigate,
        currentRoute,
        isActiveRoute,
        openInBrowser
    };
}