import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalType } from "../../config/ModalTypes";
import { AppRoute } from "../../routes/AppRouter";

interface IUiSlice {
    logoVisible: boolean
}

export const UiSlice = createSlice({
    name: "ui",
    initialState: {
        logoVisible: true
    } as IUiSlice,
    reducers: {
        setLogoVisible: (state, action: PayloadAction<boolean>) => {
            state.logoVisible = action.payload;
        }
    }
});

export const { setLogoVisible } = UiSlice.actions;

export default UiSlice.reducer;